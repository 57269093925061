import { Col, Row } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import '../Style.scss';
import WhoWeAre from '../../contentFiles/WhoWeAre.json'
import AboutUsCarousel from './carousel/AboutUsCarousel';
function AboutUs() {
  return (
    <Container className="aboutContainer">
        <Row className='rowHeader'>
            <Col className='textAlignCenter'>
                <h1>About Us</h1>
            </Col>
        </Row>
        <Row className='textJustify custPadd'>
            <Col>{WhoWeAre.aboutUs}</Col>
        </Row>
        <Row>
              <Col lg={12} xl={12} style={{textAlign:'center'}}>
                 <AboutUsCarousel />
                 </Col>
        </Row>
       
    </Container>
  );
}

export default AboutUs;