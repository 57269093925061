import Carousel from 'react-bootstrap/Carousel';
import './carousel.scss';
/** Tiyas School */
import image1 from '../../../assets/TiyasSchool/DSC_0144 (2).JPG';
import image2 from '../../../assets/TiyasSchool/DSC_0144.JPG';
import image3 from '../../../assets/TiyasSchool/DSC_0161.JPG';
import image4 from '../../../assets/TiyasSchool/DSC_0183 (1).JPG';
import image5 from '../../../assets/TiyasSchool/DSC_0205.JPG';
import image6 from '../../../assets/TiyasSchool/DSC_0267 (1).JPG';
import image7 from '../../../assets/TiyasSchool/DSC_0771.JPG';
import image8 from '../../../assets/TiyasSchool/DSC_0851.JPG';
import image9 from '../../../assets/TiyasSchool/DSC_1286 (1).JPG';
import image10 from '../../../assets/TiyasSchool/DSC_1286.JPG';
import image11 from '../../../assets/TiyasSchool/DSC_1295.JPG';
import image12 from '../../../assets/TiyasSchool/DSC_1312.JPG';
import image13 from '../../../assets/TiyasSchool/DSC_2075.JPG';

/** Remedial Education */
import rem1 from '../../../assets/RemedialEducation/DSC_0225.JPG';
import rem2 from '../../../assets/RemedialEducation/DSC_0233.JPG';
import rem3 from '../../../assets/RemedialEducation/DSC_0247.JPG';
import rem4 from '../../../assets/RemedialEducation/DSC_0249.JPG';
import rem5 from '../../../assets/RemedialEducation/DSC_0593.JPG';
import rem6 from '../../../assets/RemedialEducation/DSC_0605.JPG';
import rem7 from '../../../assets/RemedialEducation/DSC_0606.JPG';
import rem8 from '../../../assets/RemedialEducation/DSC_0626.JPG';
import rem9 from '../../../assets/RemedialEducation/DSC_0813 (2).JPG';
import rem10 from '../../../assets/RemedialEducation/DSC_0821.JPG';
import rem11 from '../../../assets/RemedialEducation/DSC_1489 (1).JPG';

/** Scholarship Support */
import sch1 from '../../../assets/Scholarshipdistribution/DSC_2478.JPG';
import sch2 from '../../../assets/Scholarshipdistribution/DSC_2481.JPG';
import sch3 from '../../../assets/Scholarshipdistribution/DSC_2482.JPG';
import sch4 from '../../../assets/Scholarshipdistribution/DSC_2584.JPG';
import sch5 from '../../../assets/Scholarshipdistribution/DSC_2591.JPG';
import sch6 from '../../../assets/Scholarshipdistribution/DSC_2592.JPG';
import sch7 from '../../../assets/Scholarshipdistribution/DSC_2593.JPG';
import sch8 from '../../../assets/Scholarshipdistribution/DSC_2594.JPG';
import sch9 from '../../../assets/Scholarshipdistribution/DSC_2598.JPG';
import sch10 from '../../../assets/Scholarshipdistribution/DSC_2600.JPG';
import sch11 from '../../../assets/Scholarshipdistribution/DSC_2601.JPG';
import sch12 from '../../../assets/Scholarshipdistribution/DSC_2602.JPG';
import sch13 from '../../../assets/Scholarshipdistribution/DSC_2582.JPG';

/** Career Councelling */
import cc1 from '../../../assets/CareerCounselling/1.jpeg';
import cc2 from '../../../assets/CareerCounselling/2.jpeg';
import cc3 from '../../../assets/CareerCounselling/3.jpeg';
import cc4 from '../../../assets/CareerCounselling/4.jpeg';
import cc5 from '../../../assets/CareerCounselling/5.jpeg';
import cc6 from '../../../assets/CareerCounselling/6.jpeg';
import cc7 from '../../../assets/CareerCounselling/7.jpeg';
import cc8 from '../../../assets/CareerCounselling/8.jpeg';
import cc9 from '../../../assets/CareerCounselling/9.jpeg';
import cc10 from '../../../assets/CareerCounselling/10.jpeg';
/** CareAndProtection */
import cp1 from '../../../assets/CareAndProtection/DSC_4039.JPG';
import cp2 from '../../../assets/CareAndProtection/DSC_4039 (1).JPG';
import cp3 from '../../../assets/CareAndProtection/DSC_4055.JPG';
/** Disasterresponse */
import dr1 from '../../../assets/Disasterresponse/DSC_0084.JPG';
import dr2 from '../../../assets/Disasterresponse/DSC_0090.JPG';
import dr3 from '../../../assets/Disasterresponse/DSC_0093.JPG';
import dr4 from '../../../assets/Disasterresponse/DSC_0104.JPG';
import dr5 from '../../../assets/Disasterresponse/DSC_0108.JPG';
import dr6 from '../../../assets/Disasterresponse/DSC_0116.JPG';
import dr7 from '../../../assets/Disasterresponse/DSC_0117.JPG';
import dr8 from '../../../assets/Disasterresponse/DSC_0124.JPG';
import dr9 from '../../../assets/Disasterresponse/DSC_0878.JPG';
import dr10 from '../../../assets/Disasterresponse/DSC_0886.JPG';
import dr11 from '../../../assets/Disasterresponse/DSC_0890.JPG';

function AboutUsCarousel() {
  return (
    <>
    <div class="educationCarousel aboutUsSlide">
   
    <Carousel fade>
      <Carousel.Item interval={1500}>
        <img src={cp1} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={cp2} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={cp3} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={image4} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={image5} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={image6} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={image7} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={image8} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={image9} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={image10} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={image11} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={image12} class="d-block w-100" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img src={image13} class="d-block w-100" alt="..." />
        </Carousel.Item>
    </Carousel> 


   
    </div>
    </>
  );
}

export default AboutUsCarousel;